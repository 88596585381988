/* W3.CSS 4.13 June 2019 by Jan Egil and Borge Refsnes */
html{box-sizing:border-box}*,*:before,*:after{box-sizing:inherit}
html {font-size: 2.5vh} /* font-size 1em = 10px on default browser settings */
/* Extract from normalize.css by Nicolas Gallagher and Jonathan Neal git.io/normalize */
html{-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;-webkit-tap-highlight-color: transparent}body{margin:0}
article,aside,details,figcaption,figure,footer,header,main,menu,nav,section{display:block}summary{display:list-item}
audio,canvas,progress,video{display:inline-block}progress{vertical-align:baseline}
audio:not([controls]){display:none;height:0}[hidden],template{display:none}
a{background-color:transparent}a:active,a:hover{outline-width:0}
abbr[title]{border-bottom:none;text-decoration:underline}
b,strong{font-weight:bolder}dfn{font-style:italic}mark{background:#ff0;color:#000}
small{font-size:80%}sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline}
sub{bottom:-0.25em}sup{top:-0.5em}figure{margin:1em 40px}img{border-style:none}
code,kbd,pre,samp{font-family:monospace,monospace;font-size:1em}hr{box-sizing:content-box;height:0;overflow:visible}
select:invalid.paralian-invalid-gray {color: rgb(128, 128, 128) !important}
input {text-align: center; outline: transparent}
select {outline: transparent}
option {outline: transparent}
button,input,select,textarea,optgroup{font:inherit;margin:0}optgroup{font-weight:bold}
button,input{overflow:visible}button,select{text-transform:none}
button,[type=button],[type=reset],[type=submit]{-webkit-appearance:button}
button::-moz-focus-inner,[type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner{border-style:none;padding:0}
button:-moz-focusring,[type=button]:-moz-focusring,[type=reset]:-moz-focusring,[type=submit]:-moz-focusring{outline:1px dotted ButtonText}
fieldset{border-radius:1vh;padding: 1vh 2vh 2vh}
legend{color:inherit;display:table;max-width:100%;padding:0;white-space:normal}textarea{overflow:auto}
[type=checkbox],[type=radio]{padding:0}
[type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button{height:auto}
[type=search]{-webkit-appearance:textfield;outline-offset:-2px}
[type=search]::-webkit-search-decoration{-webkit-appearance:none}
::-webkit-file-upload-button{-webkit-appearance:button;font:inherit}
/* End extract */
html,body{font-family:Verdana,sans-serif}html{overflow-x:hidden} /*font-size:15px;line-height:1.5*/
h1{font-size:36px}h2{font-size:30px}h3{font-size:24px}h4{font-size:20px}h5{font-size:18px}h6{font-size:16px}.w3-serif{font-family:serif}
h1,h2,h3,h4,h5,h6{font-family:"Segoe UI",Arial,sans-serif;font-weight:400;margin:10px 0}.w3-wide{letter-spacing:4px}
hr{border:0;border-top:1px solid #eee;margin:20px 0}
.w3-image{max-width:100%;height:auto}img{vertical-align:middle}a{color:inherit}
.w3-table,.w3-table-all{border-collapse:collapse;border-spacing:0;width:100%;display:table}.w3-table-all{border:1px solid #ccc}
.w3-bordered tr,.w3-table-all tr{border-bottom:1px solid #ddd}.w3-striped tbody tr:nth-child(even){background-color:#f1f1f1}
.w3-table-all tr:nth-child(odd){background-color:#fff}.w3-table-all tr:nth-child(even){background-color:#f1f1f1}
.w3-hoverable tbody tr:hover,.w3-ul.w3-hoverable li:hover{background-color:#ccc}.w3-centered tr th,.w3-centered tr td{text-align:center}
.w3-table td,.w3-table th,.w3-table-all td,.w3-table-all th{padding:8px 8px;display:table-cell;text-align:left;vertical-align:top}
.w3-table th:first-child,.w3-table td:first-child,.w3-table-all th:first-child,.w3-table-all td:first-child{padding-left:16px}
.w3-btn,.w3-button{border:none;display:inline-block;padding:8px 16px;vertical-align:middle;overflow:hidden;text-decoration:none;color:inherit;background-color:inherit;text-align:center;cursor:pointer;white-space:nowrap}
.w3-btn:hover{box-shadow:0 8px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19)}
.w3-btn,.w3-button{-webkit-touch-callout:none;-webkit-user-select:none;-khtml-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}
.w3-disabled,.w3-btn:disabled,.w3-button:disabled{cursor:not-allowed;opacity:0.3}.w3-disabled *,:disabled *{pointer-events:none}
.w3-btn.w3-disabled:hover,.w3-btn:disabled:hover{box-shadow:none}
.w3-badge,.w3-tag{background-color:#000;color:#fff;display:inline-block;padding-left:8px;padding-right:8px;text-align:center}.w3-badge{border-radius:50%}
.w3-ul{list-style-type:none;padding:0;margin:0}.w3-ul li{padding:8px 16px;border-bottom:1px solid #ddd}.w3-ul li:last-child{border-bottom:none}
.w3-tooltip,.w3-display-container{position:relative}.w3-tooltip .w3-text{display:none}.w3-tooltip:hover .w3-text{display:inline-block}
.w3-ripple:active{opacity:0.5}.w3-ripple{transition:opacity 0s}
.w3-input{padding:8px;display:block;border:none;border-bottom:1px solid #ccc;width:100%}
.w3-select{padding:9px 0;width:100%;border:none;border-bottom:1px solid #ccc}
.w3-dropdown-click,.w3-dropdown-hover{position:relative;display:inline-block;cursor:pointer}
.w3-dropdown-hover:hover .w3-dropdown-content{display:block}
.w3-dropdown-hover:first-child,.w3-dropdown-click:hover{background-color:#ccc;color:#000}
.w3-dropdown-hover:hover > .w3-button:first-child,.w3-dropdown-click:hover > .w3-button:first-child{background-color:#ccc;color:#000}
.w3-dropdown-content{cursor:auto;color:#000;background-color:#fff;display:none;position:absolute;min-width:160px;margin:0;padding:0;z-index:1}
.w3-check,.w3-radio{width:24px;height:24px;position:relative;top:6px}
.w3-sidebar{height:100%;width:200px;background-color:#fff;position:fixed!important;z-index:1;overflow:auto}
.w3-bar-block .w3-dropdown-hover,.w3-bar-block .w3-dropdown-click{width:100%}
.w3-bar-block .w3-dropdown-hover .w3-dropdown-content,.w3-bar-block .w3-dropdown-click .w3-dropdown-content{min-width:100%}
.w3-bar-block .w3-dropdown-hover .w3-button,.w3-bar-block .w3-dropdown-click .w3-button{width:100%;text-align:left;padding:8px 16px}
.w3-main,#main{transition:margin-left .4s}
.w3-modal{z-index:3;display:none;padding-top:100px;position:fixed;left:0;top:0;width:100%;height:100%;overflow:auto;background-color:rgb(0,0,0);background-color:rgba(0,0,0,0.4)}
.w3-modal-content{margin:auto;background-color:#fff;position:relative;padding:0;outline:0;width:600px}
.w3-bar{width:100%;overflow:hidden}.w3-center .w3-bar{display:inline-block;width:auto}
.w3-bar .w3-bar-item{padding:8px 16px;float:left;width:auto;border:none;display:block;outline:0}
.w3-bar .w3-dropdown-hover,.w3-bar .w3-dropdown-click{position:static;float:left}
.w3-bar .w3-button{white-space:normal}
.w3-bar-block .w3-bar-item{width:100%;display:block;padding:8px 16px;text-align:left;border:none;white-space:normal;float:none;outline:0}
.w3-bar-block.w3-center .w3-bar-item{text-align:center}.w3-block{display:block;width:100%}
.w3-responsive{display:block;overflow-x:auto}
.w3-container:after,.w3-container:before,.w3-panel:after,.w3-panel:before,.w3-row:after,.w3-row:before,.w3-row-padding:after,.w3-row-padding:before,
.w3-cell-row:before,.w3-cell-row:after,.w3-clear:after,.w3-clear:before,.w3-bar:before,.w3-bar:after{content:"";display:table;clear:both}
.w3-col,.w3-half,.w3-third,.w3-twothird,.w3-threequarter,.w3-quarter{float:left;width:100%}
.w3-col.s1{width:8.33333%}.w3-col.s2{width:16.66666%}.w3-col.s3{width:24.99999%}.w3-col.s4{width:33.33333%}
.w3-col.s5{width:41.66666%}.w3-col.s6{width:49.99999%}.w3-col.s7{width:58.33333%}.w3-col.s8{width:66.66666%}
.w3-col.s9{width:74.99999%}.w3-col.s10{width:83.33333%}.w3-col.s11{width:91.66666%}.w3-col.s12{width:99.99999%}
@media (min-width:601px){.w3-col.m1{width:8.33333%}.w3-col.m2{width:16.66666%}.w3-col.m3,.w3-quarter{width:24.99999%}.w3-col.m4,.w3-third{width:33.33333%}
  .w3-col.m5{width:41.66666%}.w3-col.m6,.w3-half{width:49.99999%}.w3-col.m7{width:58.33333%}.w3-col.m8,.w3-twothird{width:66.66666%}
  .w3-col.m9,.w3-threequarter{width:74.99999%}.w3-col.m10{width:83.33333%}.w3-col.m11{width:91.66666%}.w3-col.m12{width:99.99999%}}
@media (min-width:993px){.w3-col.l1{width:8.33333%}.w3-col.l2{width:16.66666%}.w3-col.l3{width:24.99999%}.w3-col.l4{width:33.33333%}
  .w3-col.l5{width:41.66666%}.w3-col.l6{width:49.99999%}.w3-col.l7{width:58.33333%}.w3-col.l8{width:66.66666%}
  .w3-col.l9{width:74.99999%}.w3-col.l10{width:83.33333%}.w3-col.l11{width:91.66666%}.w3-col.l12{width:99.99999%}}
.w3-rest{overflow:hidden}.w3-stretch{margin-left:-16px;margin-right:-16px}
.w3-content,.w3-auto{margin-left:auto;margin-right:auto}.w3-content{max-width:980px}.w3-auto{max-width:1140px}
.w3-cell-row{display:table;width:100%}.w3-cell{display:table-cell}
.w3-cell-top{vertical-align:top}.w3-cell-middle{vertical-align:middle}.w3-cell-bottom{vertical-align:bottom}
.w3-hide{display:none!important}.w3-show-block,.w3-show{display:block!important}.w3-show-inline-block{display:inline-block!important}
@media (max-width:1205px){.w3-auto{max-width:95%}}
@media (max-width:600px){.w3-modal-content{margin:0 10px;width:auto!important}.w3-modal{padding-top:30px}
  .w3-dropdown-hover.w3-mobile .w3-dropdown-content,.w3-dropdown-click.w3-mobile .w3-dropdown-content{position:relative}
  .w3-hide-small{display:none!important}.w3-mobile{display:block;width:100%!important}.w3-bar-item.w3-mobile,.w3-dropdown-hover.w3-mobile,.w3-dropdown-click.w3-mobile{text-align:center}
  .w3-dropdown-hover.w3-mobile,.w3-dropdown-hover.w3-mobile .w3-btn,.w3-dropdown-hover.w3-mobile .w3-button,.w3-dropdown-click.w3-mobile,.w3-dropdown-click.w3-mobile .w3-btn,.w3-dropdown-click.w3-mobile .w3-button{width:100%}}
@media (max-width:768px){.w3-modal-content{width:500px}.w3-modal{padding-top:50px}}
@media (min-width:993px){.w3-modal-content{width:900px}.w3-hide-large{display:none!important}.w3-sidebar.w3-collapse{display:block!important}}
@media (max-width:992px) and (min-width:601px){.w3-hide-medium{display:none!important}}
@media (max-width:992px){.w3-sidebar.w3-collapse{display:none}.w3-main{margin-left:0!important;margin-right:0!important}.w3-auto{max-width:100%}}
.w3-top,.w3-bottom{position:fixed;width:100%;z-index:1}.w3-top{top:0}.w3-bottom{bottom:0}
.w3-overlay{position:fixed;display:none;width:100%;height:100%;top:0;left:0;right:0;bottom:0;background-color:rgba(0,0,0,0.5);z-index:2}
.w3-display-topleft{position:absolute;left:0;top:0}.w3-display-topright{position:absolute;right:0;top:0}
.w3-display-bottomleft{position:absolute;left:0;bottom:0}.w3-display-bottomright{position:absolute;right:0;bottom:0}
.w3-display-middle{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%)}
.w3-display-left{position:absolute;top:50%;left:0%;transform:translate(0%,-50%);-ms-transform:translate(-0%,-50%)}
.w3-display-right{position:absolute;top:50%;right:0%;transform:translate(0%,-50%);-ms-transform:translate(0%,-50%)}
.w3-display-topmiddle{position:absolute;left:50%;top:0;transform:translate(-50%,0%);-ms-transform:translate(-50%,0%)}
.w3-display-bottommiddle{position:absolute;left:50%;bottom:0;transform:translate(-50%,0%);-ms-transform:translate(-50%,0%)}
.w3-display-container:hover .w3-display-hover{display:block}.w3-display-container:hover span.w3-display-hover{display:inline-block}.w3-display-hover{display:none}
.w3-display-position{position:absolute}
.w3-circle{border-radius:50%}
.w3-round-small{border-radius:2px}.w3-round,.w3-round-medium{border-radius:4px}.w3-round-large{border-radius:8px}.w3-round-xlarge{border-radius:16px}.w3-round-xxlarge{border-radius:32px}
.w3-row-padding,.w3-row-padding>.w3-half,.w3-row-padding>.w3-third,.w3-row-padding>.w3-twothird,.w3-row-padding>.w3-threequarter,.w3-row-padding>.w3-quarter,.w3-row-padding>.w3-col{padding:0 8px}
.w3-container,.w3-panel{padding:0.01em 16px}.w3-panel{margin-top:16px;margin-bottom:16px}
.w3-code,.w3-codespan{font-family:Consolas,"courier new";font-size:16px}
.w3-code{width:auto;background-color:#fff;padding:8px 12px;border-left:4px solid #4CAF50;word-wrap:break-word}
.w3-codespan{color:crimson;background-color:#f1f1f1;padding-left:4px;padding-right:4px;font-size:110%}
.w3-card,.w3-card-2{box-shadow:0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)}
.w3-card-4,.w3-hover-shadow:hover{box-shadow:0 4px 10px 0 rgba(0,0,0,0.2),0 4px 20px 0 rgba(0,0,0,0.19)}
.w3-spin{animation:w3-spin 2s infinite linear}@keyframes w3-spin{0%{transform:rotate(0deg)}100%{transform:rotate(359deg)}}
.w3-animate-fading{animation:fading 2.5s infinite}@keyframes fading{0%{opacity:0}50%{opacity:1}100%{opacity:0}}
.w3-animate-opacity{animation:opac 0.8s}@keyframes opac{from{opacity:0} to{opacity:1}}
.w3-animate-top{position:relative;animation:animatetop 0.8s}@keyframes animatetop{from{top:-50vh;opacity:0} to{top:0;opacity:1}} /*edited*/
.w3-animate-left{position:relative;animation:animateleft 0.4s}@keyframes animateleft{from{left:-300px;opacity:0} to{left:0;opacity:1}}
.w3-animate-right{position:relative;animation:animateright 0.4s}@keyframes animateright{from{right:-300px;opacity:0} to{right:0;opacity:1}}
.w3-animate-bottom{position:relative;animation:animatebottom 0.4s}@keyframes animatebottom{from{bottom:-300px;opacity:0} to{bottom:0;opacity:1}}
.w3-animate-zoom {animation:animatezoom 0.6s}@keyframes animatezoom{from{transform:scale(0)} to{transform:scale(1)}}
.w3-animate-input{transition:width 0.4s ease-in-out}.w3-animate-input:focus{width:100%!important}
.w3-opacity,.w3-hover-opacity:hover{opacity:0.60}.w3-opacity-off,.w3-hover-opacity-off:hover{opacity:1}
.w3-opacity-max{opacity:0.25}.w3-opacity-min{opacity:0.75}
.w3-greyscale-max,.w3-grayscale-max,.w3-hover-greyscale:hover,.w3-hover-grayscale:hover{filter:grayscale(100%)}
.w3-greyscale,.w3-grayscale{filter:grayscale(75%)}.w3-greyscale-min,.w3-grayscale-min{filter:grayscale(50%)}
.w3-sepia{filter:sepia(75%)}.w3-sepia-max,.w3-hover-sepia:hover{filter:sepia(100%)}.w3-sepia-min{filter:sepia(50%)}
.w3-tiny{font-size:10px!important}.w3-small{font-size:12px!important}.w3-medium{font-size:15px!important}.w3-large{font-size:18px!important}
.w3-xlarge{font-size:24px!important}.w3-xxlarge{font-size:36px!important}.w3-xxxlarge{font-size:48px!important}.w3-jumbo{font-size:64px!important}
.w3-left-align{text-align:left!important}.w3-right-align{text-align:right!important}.w3-justify{text-align:justify!important}.w3-center{text-align:center!important}
.w3-border-0{border:0!important}.w3-border{border:1px solid #ccc!important}
.w3-border-top{border-top:1px solid #ccc!important}.w3-border-bottom{border-bottom:1px solid #ccc!important}
.w3-border-left{border-left:1px solid #ccc!important}.w3-border-right{border-right:1px solid #ccc!important}
.w3-topbar{border-top:6px solid #ccc!important}.w3-bottombar{border-bottom:6px solid #ccc!important}
.w3-leftbar{border-left:6px solid #ccc!important}.w3-rightbar{border-right:6px solid #ccc!important}
.w3-section,.w3-code{margin-top:16px!important;margin-bottom:16px!important}
.w3-margin{margin:16px!important}.w3-margin-top{margin-top:16px!important}.w3-margin-bottom{margin-bottom:16px!important}
.w3-margin-left{margin-left:16px!important}.w3-margin-right{margin-right:16px!important}
.w3-padding-small{padding:4px 8px!important}.w3-padding{padding:8px 16px!important}.w3-padding-large{padding:12px 24px!important}
.w3-padding-16{padding-top:16px!important;padding-bottom:16px!important}.w3-padding-24{padding-top:24px!important;padding-bottom:24px!important}
.w3-padding-32{padding-top:32px!important;padding-bottom:32px!important}.w3-padding-48{padding-top:48px!important;padding-bottom:48px!important}
.w3-padding-64{padding-top:64px!important;padding-bottom:64px!important}
.w3-left{float:left!important}.w3-right{float:right!important}
.w3-button:hover{color:#000!important;background-color:#ccc!important}
.w3-transparent,.w3-hover-none:hover{background-color:transparent!important}
.w3-hover-none:hover{box-shadow:none!important}
/* File Upload */
.paralian-centered {margin: auto}
/* Calendar input chrome */
.paralian-theme-level-1::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23FBF0D6" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>')}
.paralian-theme-level-2::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23FBF0D6" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>')}
/* Paralian Buttons */
.paralian-button:active{opacity:0.5}.paralian-button{transition:opacity 0s}
.paralian-next:after{content:"\F054"!important}
.paralian-next:hover:after{content:"\F138"!important}
/* Paralian Scrollbars */
.paralian-scroll {overflow-x: hidden; overflow-y: auto} /* Allow vertical scrolling inside element */
.paralian-hide-scroll-chrome::-webkit-scrollbar {display: none!important} /* Hide scrollbar for Chrome, Safari and Opera */
.paralian-hide-scroll-ms {-ms-overflow-style: none!important} /* Hide scrollbar for IE and Edge */
/* Paralian Colors */
.paralian-theme-level-1,.paralian-theme-level-1:hover{color:rgb(251,240,214)!important;background-color:rgba(0,0,40,1.0)!important}
.paralian-theme-level-2,.paralian-theme-level-2:hover{color:rgb(251,240,214)!important;background-color:rgb(52,70,99)!important}
.paralian-theme-level-3,.paralian-theme-level-3:hover{color:rgb(251,240,214)!important;background-color:rgb(69,94,112)!important}
.paralian-theme-level-4,.paralian-theme-level-4:hover{color:rgb(181,178,154)!important;background-color:rgb(0,0,40)!important}
.paralian-theme-level-1-flipped,.paralian-theme-level-1-flipped:hover{color:rgb(0,0,40)!important;background-color:rgb(251,240,214)!important}
.paralian-theme-level-2-flipped,.paralian-theme-level-2-flipped:hover{color:rgb(52,70,99)!important;background-color:rgb(251,240,214)!important}
.paralian-theme-level-3-flipped,.paralian-theme-level-3-flipped:hover{color:rgb(69,94,112)!important;background-color:rgb(251,240,214)!important}
.paralian-theme-level-4-flipped,.paralian-theme-level-4-flipped:hover{color:rgb(0,0,40)!important;background-color:rgb(181,178,154)!important}

.paralian-sky-blue,.paralian-hover-sky-blue:hover{color:rgb(69,94,112)!important;background-color:rgb(220,231,240)!important}
.paralian-theme-level-1-flipped-blue,.paralian-hover-blue-gray-blue:hover{color:rgb(69,94,112)!important;background-color:rgb(31,59,80)!important}
/* .paralian-theme-level-3,.paralian-hover-light-gray:hover{color:rgb(31,59,80)!important;background-color:#f1f1f1!important} */
/* .paralian-theme-level-4,.paralian-hover-gold:hover{color:rgb(0,0,40)!important;background-color:rgb(251,240,214)!important} */

.paralian-theme-level-4-transparent,.paralian-hover-gold-transparent:hover{color:rgb(31,59,80)!important;background-color:transparent!important}
.paralian-theme-level-1-white{color:rgb(31,59,80)!important;background-color:rgb(255,255,255)!important}
.paralian-text-light-grey,.paralian-hover-text-light-grey:hover,.paralian-text-light-gray,.paralian-hover-text-light-gray:hover{color: #c9c9c9 !important}
.paralian-text-blue,.paralian-hover-text-blue:hover{color: rgb(0,0,40) !important}
/* .paralian-text-light-theme-level-1,.paralian-hover-text-gold:hover{color: rgb(255,255,255) !important} */
.paralian-text-light-theme-level-1,.paralian-hover-text-gold:hover{color: rgb(251,240,214) !important}
.paralian-text-light-theme-level-1::-webkit-input-placeholder {color: rgb(251,240,214)!important;}
.paralian-text-light-theme-level-1:-ms-input-placeholder {color: rgb(251,240,214)!important;}
.paralian-text-light-theme-level-1::placeholder {color: rgb(251,240,214)!important;}
.paralian-border-blue,.paralian-hover-border-blue:hover{border-color:rgb(69,94,112)!important}
.paralian-ok,.paralian-hover-ok{color:rgb(31,59,80)!important;background-color:rgb(231,218,179)!important}
.paralian-warning,.paralian-hover-warning:hover{color: rgb(224, 163, 0)!important;background-color:rgb(231,218,179)!important}
.paralian-alert,.paralian-hover-alert:hover{color: rgb(179, 32, 0)!important;background-color:rgb(231,218,179)!important}
.paralian-font{font-size: 2.5vw;letter-spacing: 0.5vw;font-family: Georgia Pro Light, serif!important;}
/* Paralian Overflow */
.paralian-overflow{white-space: nowrap!important; overflow: hidden!important; text-overflow: ellipsis!important}
.paralian-overflow-nowrap-ellipsis{white-space: nowrap!important; overflow: hidden!important; text-overflow: ellipsis!important}
.paralian-overflow-ellipsis{overflow: hidden!important; text-overflow: ellipsis!important}
.paralian-overflow:hover{overflow: visible!important; background-color:rgb(69,94,112)!important}
.paralian-overflow-scroll{overflow: scroll!important; text-overflow: unset!important; white-space: nowrap!important}
/* Paralian Flatpickr */
.paralianDateTimeInput{width: 22vw; font-size: 1vw; text-align: center; display: inline-block}
/* Paralian Loader */
.Loader {border: 1vh solid rgba(220,231,240,0.25); border-radius: 50%; border-top: 1vh solid rgba(31,59,80,0.25); width: 7vh; height: 7vh}
.loader-mini {border: 1vh solid rgba(220,231,240,0.25); border-radius: 50%; border-top: 1vh solid rgba(31,59,80,0.25); width: 2vh; height: 2vh}
/* loading.io Loader */
.lds-ellipsis {display: inline-block; position: relative; width: 80px; height: 80px;}
.lds-ellipsis div {position: absolute; top: 33px; width: 13px; height: 13px; border-radius: 50%; background: rgb(231,218,179); animation-timing-function: cubic-bezier(0, 1, 1, 0);}
.lds-ellipsis div:nth-child(1) {left: 8px; animation: lds-ellipsis1 0.6s infinite;}
.lds-ellipsis div:nth-child(2) {left: 8px; animation: lds-ellipsis2 0.6s infinite;}
.lds-ellipsis div:nth-child(3) {left: 32px; animation: lds-ellipsis2 0.6s infinite;}
.lds-ellipsis div:nth-child(4) {left: 56px; animation: lds-ellipsis3 0.6s infinite;}
@keyframes lds-ellipsis1 {0% {transform: scale(0);} 100% {transform: scale(1);}}
@keyframes lds-ellipsis3 {0% {transform: scale(1);} 100% {transform: scale(0);}}
@keyframes lds-ellipsis2 {0% {transform: translate(0, 0);} 100% {transform: translate(24px, 0);}}
/* Paralian Fields */
.paralian-field-icon {padding: 1vh; font-size: 4vh; height: 6vh; width: 8vh; outline: transparent; font-weight: 500}
/* Paralian Mobile Fields */
.paralian-mobile-field-container {margin: 2vh 4vw; width: 92vw; max-width: 60vmax; outline: transparent}
.paralian-mobile-field-light {background-color: rgba(231,218,179,0.1)!important;}
.paralian-mobile-field-light-opaque {background-color: rgba(231,218,179,0.4)!important;}
.paralian-mobile-field-dark {background-color: rgba(31,59,80,0.1)!important;}
/* Paralian MobileMain */
.paralian-mobile-content{height: 83vh; width: 100%} /* padding-top: 8vh */
/* .paralian-mobile-info-card{background-color: rgb(69,94,112); margin: 2vh; padding: 2vh} */
.paralian-mobile-info-card{background-color: rgb(52,70,99); margin: 2vh; padding: 2vh; border-radius: 1vh}
.paralian-mobile-info-card-header{font-size: 2vh; opacity: 0.5}
/* Paralian Images */
.paralian-fullscreen{height: auto; width: 100vw}
@media (orientation: landscape) {.paralian-fullscreen {height: 100vh; width: auto}}
/* Paralian Sticky */
.sticky {position: -webkit-sticky; position: sticky; top: 0;}
.stickyLevel-2 {position: -webkit-sticky; position: sticky; top: 6vh;}
/* Paralian Autofill */
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {-webkit-text-fill-color: rgb(231,218,179) !important; -webkit-box-shadow:inset 0 0 0 4vh rgb(71,91,100) !important}
input{outline: transparent}
/* Paralian Input Field */
.paralian-input{background-color: inherit; color: inherit; border: inherit; text-align: center}
.paralian-no-drop-arrow{-o-appearance: none; -ms-appearance: none; -webkit-appearance: none; -moz-appearance: none; appearance: none}
.paralian-input-field{height: 100%; font-size: 2.5vh; text-align: center; background-color: unset; width: 100%; border: none; border-radius: 1vh}
/* Paralian Date Field */
.paralian-date-field{height: 100%; font-size: 2.5vh; text-align: center; width: 100%; max-width: 50vmax; border: none}
/* Paralian Label Field */
.paralian-label-field{font-size: 2vh; text-align: left; padding: 0.5vh}
/* Paralian Input Icons */
.paralian-input-icons{height: 6vh; width: 6vh; min-width: 6vh; font-size: 3vh; padding: 1vh 0vh 1vh 2vh}
/* Document Display */
.paralian-document-display{width: 80vw; height: 60vh; display: block; position: relative}
/* Toggle Slider */
.switch {position: relative; display: block; width: 10vw; height: 2.5vh; margin: 1.5vh 3vw} /* The switch - the box around the slider */
.switch input {opacity: 0; width: 0; height: 0;} /* Hide default HTML checkbox */
.slider {position: absolute; cursor: pointer; top: 0; left: 0; right: 0; bottom: 0; background-color: #ccc; -webkit-transition: .4s; transition: .4s;} /* The slider */
.slider:before {position: absolute; content: ""; height: 2vh; width: 2vh; left: 0.5vh; bottom: 0.5vh; background-color: white; -webkit-transition: .4s; transition: .4s;}
input:invalid {border: solid rgba(255, 0, 0, 0.3)}
/* input:valid {border: solid rgba(0, 255, 0, 0.3)} */
input:checked + .slider {background-color: #2196F3;}
input:focus + .slider {box-shadow: 0 0 1px #2196F3;}
input:checked + .slider:before {-webkit-transform: translateX(5vh); -ms-transform: translateX(5vh); transform: translateX(5vh);}
.slider.round {border-radius: 2.5vh;} /* Rounded sliders */
.slider.round:before {border-radius: 50%;}
/* Colors */
.w3-amber,.w3-hover-amber:hover{color:#000!important;background-color:#ffc107!important}
.w3-aqua,.w3-hover-aqua:hover{color:#000!important;background-color:#00ffff!important}
.w3-blue,.w3-hover-blue:hover{color:#fff!important;background-color:#2196F3!important}
.w3-light-blue,.w3-hover-light-blue:hover{color:#000!important;background-color:#87CEEB!important}
.w3-brown,.w3-hover-brown:hover{color:#fff!important;background-color:#795548!important}
.w3-cyan,.w3-hover-cyan:hover{color:#000!important;background-color:#00bcd4!important}
.w3-blue-grey,.w3-hover-blue-grey:hover,.w3-blue-gray,.w3-hover-blue-gray:hover{color:#fff!important;background-color:#607d8b!important}
.w3-green,.w3-hover-green:hover{color:#fff!important;background-color:#4CAF50!important}
.w3-light-green,.w3-hover-light-green:hover{color:#000!important;background-color:#8bc34a!important}
.w3-indigo,.w3-hover-indigo:hover{color:#fff!important;background-color:#3f51b5!important}
.w3-khaki,.w3-hover-khaki:hover{color:#000!important;background-color:#f0e68c!important}
.w3-lime,.w3-hover-lime:hover{color:#000!important;background-color:#cddc39!important}
.w3-orange,.w3-hover-orange:hover{color:#000!important;background-color:#ff9800!important}
.w3-deep-orange,.w3-hover-deep-orange:hover{color:#fff!important;background-color:#ff5722!important}
.w3-pink,.w3-hover-pink:hover{color:#fff!important;background-color:#e91e63!important}
.w3-purple,.w3-hover-purple:hover{color:#fff!important;background-color:#9c27b0!important}
.w3-deep-purple,.w3-hover-deep-purple:hover{color:#fff!important;background-color:#673ab7!important}
.w3-red,.w3-hover-red:hover{color:#fff!important;background-color:#f44336!important}
.w3-sand,.w3-hover-sand:hover{color:#000!important;background-color:#fdf5e6!important}
.w3-teal,.w3-hover-teal:hover{color:#fff!important;background-color:#009688!important}
.w3-yellow,.w3-hover-yellow:hover{color:#000!important;background-color:#ffeb3b!important}
.w3-white,.w3-hover-white:hover{color:#000!important;background-color:#fff!important}
.w3-black,.w3-hover-black:hover{color:#fff!important;background-color:#000!important}
.w3-grey,.w3-hover-grey:hover,.w3-gray,.w3-hover-gray:hover{color:#000!important;background-color:#9e9e9e!important}
.w3-light-grey,.w3-hover-light-grey:hover,.w3-light-gray,.w3-hover-light-gray:hover{color:#000!important;background-color:#f1f1f1!important}
.w3-dark-grey,.w3-hover-dark-grey:hover,.w3-dark-gray,.w3-hover-dark-gray:hover{color:#fff!important;background-color:#616161!important}
.w3-pale-red,.w3-hover-pale-red:hover{color:#000!important;background-color:#ffdddd!important}
.w3-pale-green,.w3-hover-pale-green:hover{color:#000!important;background-color:#ddffdd!important}
.w3-pale-yellow,.w3-hover-pale-yellow:hover{color:#000!important;background-color:#ffffcc!important}
.w3-pale-blue,.w3-hover-pale-blue:hover{color:#000!important;background-color:#ddffff!important}
.w3-text-amber,.w3-hover-text-amber:hover{color:#ffc107!important}
.w3-text-aqua,.w3-hover-text-aqua:hover{color:#00ffff!important}
.w3-text-blue,.w3-hover-text-blue:hover{color:#2196F3!important}
.w3-text-light-blue,.w3-hover-text-light-blue:hover{color:#87CEEB!important}
.w3-text-brown,.w3-hover-text-brown:hover{color:#795548!important}
.w3-text-cyan,.w3-hover-text-cyan:hover{color:#00bcd4!important}
.w3-text-blue-grey,.w3-hover-text-blue-grey:hover,.w3-text-blue-gray,.w3-hover-text-blue-gray:hover{color:#607d8b!important}
.w3-text-green,.w3-hover-text-green:hover{color:#4CAF50!important}
.w3-text-light-green,.w3-hover-text-light-green:hover{color:#8bc34a!important}
.w3-text-indigo,.w3-hover-text-indigo:hover{color:#3f51b5!important}
.w3-text-khaki,.w3-hover-text-khaki:hover{color:#b4aa50!important}
.w3-text-lime,.w3-hover-text-lime:hover{color:#cddc39!important}
.w3-text-orange,.w3-hover-text-orange:hover{color:#ff9800!important}
.w3-text-deep-orange,.w3-hover-text-deep-orange:hover{color:#ff5722!important}
.w3-text-pink,.w3-hover-text-pink:hover{color:#e91e63!important}
.w3-text-purple,.w3-hover-text-purple:hover{color:#9c27b0!important}
.w3-text-deep-purple,.w3-hover-text-deep-purple:hover{color:#673ab7!important}
.w3-text-red,.w3-hover-text-red:hover{color:#f44336!important}
.w3-text-sand,.w3-hover-text-sand:hover{color:#fdf5e6!important}
.w3-text-teal,.w3-hover-text-teal:hover{color:#009688!important}
.w3-text-yellow,.w3-hover-text-yellow:hover{color:#d2be0e!important}
.w3-text-white,.w3-hover-text-white:hover{color:#fff!important}
.w3-text-black,.w3-hover-text-black:hover{color:#000!important}
.w3-text-grey,.w3-hover-text-grey:hover,.w3-text-gray,.w3-hover-text-gray:hover{color:#757575!important}
.w3-text-light-grey,.w3-hover-text-light-grey:hover,.w3-text-light-gray,.w3-hover-text-light-gray:hover{color:#f1f1f1!important}
.w3-text-dark-grey,.w3-hover-text-dark-grey:hover,.w3-text-dark-gray,.w3-hover-text-dark-gray:hover{color:#3a3a3a!important}
.w3-border-amber,.w3-hover-border-amber:hover{border-color:#ffc107!important}
.w3-border-aqua,.w3-hover-border-aqua:hover{border-color:#00ffff!important}
.w3-border-blue,.w3-hover-border-blue:hover{border-color:#2196F3!important}
.w3-border-light-blue,.w3-hover-border-light-blue:hover{border-color:#87CEEB!important}
.w3-border-brown,.w3-hover-border-brown:hover{border-color:#795548!important}
.w3-border-cyan,.w3-hover-border-cyan:hover{border-color:#00bcd4!important}
.w3-border-blue-grey,.w3-hover-border-blue-grey:hover,.w3-border-blue-gray,.w3-hover-border-blue-gray:hover{border-color:#607d8b!important}
.w3-border-green,.w3-hover-border-green:hover{border-color:#4CAF50!important}
.w3-border-light-green,.w3-hover-border-light-green:hover{border-color:#8bc34a!important}
.w3-border-indigo,.w3-hover-border-indigo:hover{border-color:#3f51b5!important}
.w3-border-khaki,.w3-hover-border-khaki:hover{border-color:#f0e68c!important}
.w3-border-lime,.w3-hover-border-lime:hover{border-color:#cddc39!important}
.w3-border-orange,.w3-hover-border-orange:hover{border-color:#ff9800!important}
.w3-border-deep-orange,.w3-hover-border-deep-orange:hover{border-color:#ff5722!important}
.w3-border-pink,.w3-hover-border-pink:hover{border-color:#e91e63!important}
.w3-border-purple,.w3-hover-border-purple:hover{border-color:#9c27b0!important}
.w3-border-deep-purple,.w3-hover-border-deep-purple:hover{border-color:#673ab7!important}
.w3-border-red,.w3-hover-border-red:hover{border-color:#f44336!important}
.w3-border-sand,.w3-hover-border-sand:hover{border-color:#fdf5e6!important}
.w3-border-teal,.w3-hover-border-teal:hover{border-color:#009688!important}
.w3-border-yellow,.w3-hover-border-yellow:hover{border-color:#ffeb3b!important}
.w3-border-white,.w3-hover-border-white:hover{border-color:#fff!important}
.w3-border-black,.w3-hover-border-black:hover{border-color:#000!important}
.w3-border-grey,.w3-hover-border-grey:hover,.w3-border-gray,.w3-hover-border-gray:hover{border-color:#9e9e9e!important}
.w3-border-light-grey,.w3-hover-border-light-grey:hover,.w3-border-light-gray,.w3-hover-border-light-gray:hover{border-color:#f1f1f1!important}
.w3-border-dark-grey,.w3-hover-border-dark-grey:hover,.w3-border-dark-gray,.w3-hover-border-dark-gray:hover{border-color:#616161!important}
.w3-border-pale-red,.w3-hover-border-pale-red:hover{border-color:#ffe7e7!important}.w3-border-pale-green,.w3-hover-border-pale-green:hover{border-color:#e7ffe7!important}
.w3-border-pale-yellow,.w3-hover-border-pale-yellow:hover{border-color:#ffffcc!important}.w3-border-pale-blue,.w3-hover-border-pale-blue:hover{border-color:#e7ffff!important}
/* Calendar */
/* @import url("https://fonts.googleapis.com/css?family=Roboto"); */
.rlc-calendar{font-family:Roboto,sans-serif;width: 100%;
  color: rgb(31,59,80)} /* box-shadow:0 0 6px -4px rgba(0, 0, 0, 1); */
.rlc-days,.rlc-days-label{width:100%}
.rlc-days-label{text-transform:uppercase;font-size:10px; font-weight: bold;background-color:rgba(231,218,179,0.5)}
.rlc-days{font-size:12px; background-color:rgba(231,218,179,0.5)}
.rlc-day,.rlc-day-label{display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;
  -webkit-justify-content:space-around;-ms-flex-pack:distribute;justify-content:space-around;
  -webkit-align-items:center;-ms-flex-align:center;align-items:center;width:40px;height:40px;
  text-align:center;box-sizing:border-box}
.rlc-day{cursor:pointer;-webkit-user-select:none;-ms-user-select:none;user-select:none}
.rlc-day-today{color: white; font-weight: bold}
.rlc-day-default:hover{background-color:#77b8dd;color:#868686;border-radius:50%}
.rlc-day-out-of-month{color:#d2d2d2}
.rlc-day-end-selection,.rlc-day-inside-selection,.rlc-day-selected,.rlc-day-start-selection{color:rgb(31,59,80);background-color:rgb(231,218,179)}
.rlc-day-end-selection:hover,.rlc-day-inside-selection:hover,.rlc-day-selected:hover,.rlc-day-start-selection:hover{background-color:rgb(231,218,179)}
.rlc-day-selected{border-radius:50%}
.rlc-day-start-selection{border-radius:50% 0 0 0}
.rlc-day-end-selection{border-radius:0 0 50% 0}
.rlc-day-inside-selection{border-radius:0}
.rlc-day-disabled{border-radius:0;cursor:default;color:#cecece}
.rlc-day-disabled,.rlc-day-disabled:hover{background-color: rgba(255,255,255,0.1); text-decoration: line-through}
.rlc-title-wrapper{-webkit-align-items:center;
  -ms-flex-align:center;align-items:center;-webkit-justify-content:space-between;-ms-flex-pack:justify;
  justify-content:space-between; width: 100%;
  padding:3px; font-size: 18px; font-weight: bold;
  color: rgb(31,59,80); background-color:rgba(231,218,179,0.8)}
.rlc-month-and-year-wrapper{display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;
  -ms-flex-align:center;align-items:center;-webkit-justify-content:space-between;-ms-flex-pack:justify;
  justify-content:space-between;border-bottom:1px solid #e5e5e5;border-top:1px solid #e5e5e5;padding:10px 0;
  background-color:rgba(231,218,179,0.7)}
.rlc-month-and-year{-webkit-flex-grow:2;-ms-flex-positive:2;flex-grow:2;text-align:center;text-transform:uppercase;
  font-size:14px}
.rlc-navigation-button-wrapper{display:-webkit-flex;display:-ms-flexbox;display:flex;
  -webkit-justify-content:space-around;-ms-flex-pack:distribute;justify-content:space-around;
  -webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;-webkit-user-select:none;-ms-user-select:none;user-select:none}
.rlc-navigation-button{cursor:pointer}
.rlc-details{display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-justify-content:space-between;
  -ms-flex-pack:justify;justify-content:space-between; color:rgb(31,59,80);background-color:rgba(231,218,179,0.8)}
.rlc-date-details-wrapper{margin:5px 0}
.rlc-date-details,.rlc-date-details-wrapper{-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1}
.rlc-date-details{display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;
  -ms-flex-align:center;align-items:center}
.rlc-date-number{font-size:35px;padding:0 5px}
.rlc-date-day-month-year{font-size:12px}
.rlc-detail-day{margin-bottom:2px}
.rlc-detail-month-year{text-transform:uppercase}
.rlc-date-time-selects{padding:0 10px}
.rlc-separator{margin:0 5px}
.rlc-day-marked:after{content:"";width:5px;height:5px;border-radius:50%;background-color:red;margin-top:11.5px;
  z-index:1;position:absolute}
.rlc-day-today.rlc-day-marked:after{background-color:#fff}
/* Stripe */
.FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: #7795f8;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
  inset 0 1px 0 #829fff;
  border-radius: 4px;
}

.FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid #819efc;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}